export const coursesArray = [
  {
    id: 1,
    image: 'https://eduma.thimpress.com/demo-udemy/wp-content/uploads/sites/93/2024/04/business-cat.png',
    text: 'Business',
  },
  {
    id: 2,
    image: 'https://eduma.thimpress.com/demo-udemy/wp-content/uploads/sites/93/2024/04/design-cat.png',
    text: 'Design',
  },
  {
    id: 3,
    image: 'https://eduma.thimpress.com/demo-udemy/wp-content/uploads/sites/93/2024/04/Health-Fitness.png',
    text: 'Health & Fitness',
  },
  {
    id: 4,
    image: 'https://eduma.thimpress.com/demo-udemy/wp-content/uploads/sites/93/2024/04/it-cat.png',
    text: 'IT & Software',
  },
  {
    id: 5,
    image: 'https://eduma.thimpress.com/demo-udemy/wp-content/uploads/sites/93/2024/04/Language-cat.png',
    text: 'Language',
  },
  {
    id: 6,
    image: 'https://eduma.thimpress.com/demo-udemy/wp-content/uploads/sites/93/2024/04/life-style.png',
    text: 'Lifestyle ',
  },
  {
    id: 7,
    image: 'https://eduma.thimpress.com/demo-udemy/wp-content/uploads/sites/93/2024/04/dev-cat.png',
    text: 'Marketing',
  },
  {
    id: 8,
    image: 'https://eduma.thimpress.com/demo-udemy/wp-content/uploads/sites/93/2024/04/marketing-cat.png',
    text: 'Photography & Video',
  },
];

const cooperatingArray = [
    {
        id: 1,
        image: 'https://eduma.thimpress.com/demo-udemy/wp-content/uploads/sites/93/2022/06/icon-partner-1-1-120x120.png',
        name: 'Design university',
    },
    {
        id: 2,
        image: 'https://eduma.thimpress.com/demo-udemy/wp-content/uploads/sites/93/2022/06/icon-partner-3-1-120x120.png',
        name: 'Design university',
    },
    {
        id: 3,
        image: 'https://eduma.thimpress.com/demo-udemy/wp-content/uploads/sites/93/2022/06/icon-partner-2-1-120x120.png',
        name: 'Design university',
    },

    {
        id: 4,
        image: 'https://eduma.thimpress.com/demo-udemy/wp-content/uploads/sites/93/2022/06/icon-partner-4-1-120x120.png',
        name: 'Design university',
    },

    {
        id: 5,
        image: 'https://eduma.thimpress.com/demo-udemy/wp-content/uploads/sites/93/2022/06/icon-partner-5-1-120x120.png',
        name: 'Design university',
    }
];

export default cooperatingArray;

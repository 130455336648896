const en = {
    translation: {
        part1: 'English part1',
        HOME: "Home",
        ABOUT_US: "About us",
        BLOG: "blog",
        CONTACTS: "Contacts",
        COURSES: "Courses",
        EVENTS: "events",
        TERMINATES: "Terminates",
        GALLERY: "GALLERY"
    },
};

export default en
const certifucateArray = [
  {
    id: 1,
    image: 'https://eduma.thimpress.com/wp-content/uploads/2022/11/certificates-new-1-2.png',
    name: 'Certificate of Training',
  },
  {
    id: 2,
    image: 'https://eduma.thimpress.com/wp-content/uploads/2022/11/certificates-new-2-2.png',
    name: 'Course Completion',
  },
  {
    id: 3,
    image: 'https://eduma.thimpress.com/wp-content/uploads/2022/11/certificates-new-3-2.png',
    name: 'Digital Marketing',
  },
  {
    id: 4,
    image: 'https://eduma.thimpress.com/wp-content/uploads/2022/11/certificates-new-4-2.png',
    name: 'Data Analytics',
  },
];

export default certifucateArray;

const eventsArray = [{
    id: 1,
    day: 30,
    month: 'September',
    title: 'Autumn Science Lectures',
    hour: '08:00 - 17:00',
    place: 'London,UK',
    eventDescription: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
    description: 'Morbi accumsan ipsum velit. Nam nec tellus a odio tincidunt auctor a ornare odio. Sed non mauris itae erat conuat',
    image: 'https://eduma.thimpress.com/wp-content/uploads/2015/11/event-7-450x233.jpg',
    status: 'happening',
    outcomed: ['Over 37 lectures and 55.5 hours of content!', 'LIVE PROJECT End to End Software Testing Training Included.', 'Learn Software Testing and Automation basics from a professional trainer from your own desk.', 'Information packed practical training starting from basics to advanced testing techniques.', 'Best suitable for beginners to advanced level users and who learn faster when demonstrated.', 'Course content designed by considering current software testing technology and the job market.', 'Practical assignments at the end of every session.', 'Practical learning experience with live project work and examples.',],
    totalSlots: 110,
    bookedSlots: 5,
    cost: 0,
}, {
    id: 2,
    day: 15,
    month: 'November',
    title: 'Positive Psychology',
    hour: '07:00 - 06:00',
    place: 'London,UK',
    eventDescription: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
    description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the',
    image: 'https://eduma.thimpress.com/wp-content/uploads/2015/11/event-1-450x233.jpg',
    status: 'upcoming',
    outcomed: ['Over 37 lectures and 55.5 hours of content!', 'LIVE PROJECT End to End Software Testing Training Included.', 'Learn Software Testing and Automation basics from a professional trainer from your own desk.', 'Information packed practical training starting from basics to advanced testing techniques.', 'Best suitable for beginners to advanced level users and who learn faster when demonstrated.', 'Course content designed by considering current software testing technology and the job market.', 'Practical assignments at the end of every session.', 'Practical learning experience with live project work and examples.',],
    totalSlots: 110,
    bookedSlots: 5,
    cost: 0,
}, {
    id: 3,
    day: 23,
    month: 'December',
    title: 'Build Education Website Using WordPress',
    hour: '00:00 - 17:00',
    place: 'Birmingham,UK',
    eventDescription: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
    description: 'Morbi accumsan ipsum velit. Nam nec tellus a odio tincidunt auctor a ornare odio. Sed non mauris itae erat conuat',
    image: 'https://eduma.thimpress.com/wp-content/uploads/2015/12/event-3-450x233.jpg',
    status: 'expired',
    outcomed: ['Over 37 lectures and 55.5 hours of content!', 'LIVE PROJECT End to End Software Testing Training Included.', 'Learn Software Testing and Automation basics from a professional trainer from your own desk.', 'Information packed practical training starting from basics to advanced testing techniques.', 'Best suitable for beginners to advanced level users and who learn faster when demonstrated.', 'Course content designed by considering current software testing technology and the job market.', 'Practical assignments at the end of every session.', 'Practical learning experience with live project work and examples.',],
    totalSlots: 110,
    bookedSlots: 5,
    cost: 0,
}, {
    id: 4,
    day: 23,
    month: 'December',
    title: 'Build Education Website Using WordPress',
    hour: '00:00 - 17:00',
    place: 'Birmingham,UK',
    eventDescription: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
    description: 'Morbi accumsan ipsum velit. Nam nec tellus a odio tincidunt auctor a ornare odio. Sed non mauris itae erat conuat',
    image: 'https://eduma.thimpress.com/wp-content/uploads/2015/12/event-3-450x233.jpg',
    status: 'happening',
    outcomed: ['Over 37 lectures and 55.5 hours of content!', 'LIVE PROJECT End to End Software Testing Training Included.', 'Learn Software Testing and Automation basics from a professional trainer from your own desk.', 'Information packed practical training starting from basics to advanced testing techniques.', 'Best suitable for beginners to advanced level users and who learn faster when demonstrated.', 'Course content designed by considering current software testing technology and the job market.', 'Practical assignments at the end of every session.', 'Practical learning experience with live project work and examples.',],
    totalSlots: 110,
    bookedSlots: 5,
    cost: 0,
}, {
    id: 5,
    day: 23,
    month: 'December',
    title: 'Build Education Website Using WordPress',
    hour: '00:00 - 17:00',
    place: 'Birmingham,UK',
    eventDescription: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
    description: 'Morbi accumsan ipsum velit. Nam nec tellus a odio tincidunt auctor a ornare odio. Sed non mauris itae erat conuat',
    image: 'https://eduma.thimpress.com/wp-content/uploads/2015/12/event-3-450x233.jpg',
    status: 'upcoming',
    outcomed: ['Over 37 lectures and 55.5 hours of content!', 'LIVE PROJECT End to End Software Testing Training Included.', 'Learn Software Testing and Automation basics from a professional trainer from your own desk.', 'Information packed practical training starting from basics to advanced testing techniques.', 'Best suitable for beginners to advanced level users and who learn faster when demonstrated.', 'Course content designed by considering current software testing technology and the job market.', 'Practical assignments at the end of every session.', 'Practical learning experience with live project work and examples.',],
    totalSlots: 110,
    bookedSlots: 5,
    cost: 0,
}, {
    id: 6,
    day: 15,
    month: 'November',
    title: 'Positive Psychology',
    hour: '07:00 - 06:00',
    place: 'London,UK',
    eventDescription: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
    description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the',
    image: 'https://eduma.thimpress.com/wp-content/uploads/2015/11/event-1-450x233.jpg',
    status: 'happening',
    outcomed: ['Over 37 lectures and 55.5 hours of content!', 'LIVE PROJECT End to End Software Testing Training Included.', 'Learn Software Testing and Automation basics from a professional trainer from your own desk.', 'Information packed practical training starting from basics to advanced testing techniques.', 'Best suitable for beginners to advanced level users and who learn faster when demonstrated.', 'Course content designed by considering current software testing technology and the job market.', 'Practical assignments at the end of every session.', 'Practical learning experience with live project work and examples.',],
    totalSlots: 110,
    bookedSlots: 5,
    cost: 0,
}, {
    id: 7,
    day: 15,
    month: 'November',
    title: 'Positive Psychology',
    hour: '07:00 - 06:00',
    place: 'London,UK',
    eventDescription: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
    description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the',
    image: 'https://eduma.thimpress.com/wp-content/uploads/2015/11/event-1-450x233.jpg',
    status: 'expired',
    outcomed: ['Over 37 lectures and 55.5 hours of content!', 'LIVE PROJECT End to End Software Testing Training Included.', 'Learn Software Testing and Automation basics from a professional trainer from your own desk.', 'Information packed practical training starting from basics to advanced testing techniques.', 'Best suitable for beginners to advanced level users and who learn faster when demonstrated.', 'Course content designed by considering current software testing technology and the job market.', 'Practical assignments at the end of every session.', 'Practical learning experience with live project work and examples.',],
    totalSlots: 110,
    bookedSlots: 5,
    cost: 0,
},];

export default eventsArray;

const galleryArray = [{
    id: 1,
    name:'Soft skills',
    color:'blue',
    image: 'https://eduma.thimpress.com/wp-content/uploads/2022/06/courses-7-440x440.jpg',
    status: 2,
}, {
    id: 2,
    name:'Up your skill',
    color:'orange',
    image: 'https://eduma.thimpress.com/wp-content/uploads/2022/06/courses-19-440x440.jpg',
    status: 3,
}, {
    id: 3,
    name:'Startup Team',
    color:'red',
    image: 'https://eduma.thimpress.com/wp-content/uploads/2022/06/courses-20-440x440.jpg',
    status: 4,
}, {
    id: 4,
    name:'Home Word',
    color:'green',
    image: 'https://eduma.thimpress.com/wp-content/uploads/2022/06/courses-16-440x440.jpg',
    status: 2,
},];

export default galleryArray;

const am = {
  translation: {
    part1: 'Hayeren Text',
    HOME:"Գլխավոր էջ",
    ABOUT_US:"Մեր մասին",
    BLOG:"Բլոգ",
    CONTACTS:"Կապ մեզ հետ",
    COURSES:"Կուրսեր",
    EVENTS:"Իվենթներ",
    TERMINATES:"Terminates",
    GALLERY:"Պատկերասրահ"
  },
};

export default am;

const aboutcard2info = [
  {
    id: 1,
    image:
      'https://eduma.thimpress.com/wp-content/uploads/2022/06/courses-7.jpg',
    title: 'Who we do',
    desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  },
  {
    id: 2,
    image:
      'https://eduma.thimpress.com/wp-content/uploads/2022/06/courses-4.jpg',
    title: 'Who we are',
    desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  },
  {
    id: 3,
    image:
      'https://eduma.thimpress.com/wp-content/uploads/2022/06/courses-8.jpg',
    title: 'How it work',
    desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  },
]

export default aboutcard2info
